export const BarcodeInput = ({
  barcodeInput,
  handleBarcodeChange,
  setBarcodeInput,
}) => {
  // TODO: in order to clear the barcode from here, you need to add redux and store the barcode in global state.

  // const handleClearBarcode = () => {
  // 	console.log("Before clearing:", barcodeInput);
  // 	setBarcodeInput("");
  // 	console.log("After clearing:", barcodeInput);
  // };

  return (
    <div>
      <label htmlFor="barcode">Barcode:</label>
      <input
        type="text"
        id="barcode"
        name="barcode"
        value={barcodeInput}
        onChange={handleBarcodeChange}
        // autoFocus
      />
      <button>Clear barcode</button>
    </div>
  );
};
