import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import AlbumSelector from "./components/AlbumSelector";
import Collection from "./components/Collection";
import CollectionSummary from "./components/CollectionSummary";
import { Header } from "./components/Header";

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/:paramsBarcode" element={<Main />} />
        <Route exact path="/select/:searchString" element={<AlbumSelector />} />
        <Route exact path="/collection" element={<Collection />} />
        <Route exact path="/summary" element={<CollectionSummary />} />
      </Routes>
      {/* <Footer /> */}
    </BrowserRouter>
  );
};

export default App;
