import "../App.css";

export const ConditionTable = ({ cdObj, buttonDisabled, handleClick }) => {
  return (
    <table className="table">
      <thead>
        <tr className="table-row">
          <th className="table-column">Condition</th>
          <th className="table-column">Poor</th>
          <th className="table-column">Fair</th>
          <th className="table-column">Good</th>
          <th className="table-column">Good+</th>
          <th className="table-column">Very Good</th>
          <th className="table-column">Very Good+</th>
          <th className="table-column">Near Mint</th>
          <th className="table-column">Mint</th>
        </tr>
      </thead>
      <tbody>
        <tr className="table-row">
          <td className="table-column">Price</td>
          <td className="table-column">${cdObj.condition.poor || 0}</td>
          <td className="table-column">${cdObj.condition.fair || 0}</td>
          <td className="table-column">${cdObj.condition.good || 0}</td>
          <td className="table-column">${cdObj.condition.goodPlus || 0}</td>
          <td className="table-column">${cdObj.condition.veryGood || 0}</td>
          <td className="table-column">${cdObj.condition.veryGoodPlus || 0}</td>
          <td className="table-column">${cdObj.condition.nearMint || 0}</td>
          <td className="table-column">${cdObj.condition.mint || 0}</td>
        </tr>
        <tr className="table-row">
          <td className="table-column"></td>
          <td className="table-column">
            <button
							className={ buttonDisabled ? "disabled" : ''}
              onClick={handleClick}
              disabled={buttonDisabled}
              name="Poor (P)"
              value={cdObj.condition.poor || 0}>
              Add
            </button>
          </td>
          <td className="table-column">
            <button
							className={ buttonDisabled ? "disabled" : ''}
              onClick={handleClick}
              disabled={buttonDisabled}
              name="Fair (F)"
              value={cdObj.condition.fair || 0}>
              Add
            </button>
          </td>
          <td className="table-column">
            <button
							className={ buttonDisabled ? "disabled" : ''}
              onClick={handleClick}
              disabled={buttonDisabled}
              name="Good (G)"
              value={cdObj.condition.good || 0}>
              Add
            </button>
          </td>
          <td className="table-column">
            <button
							className={ buttonDisabled ? "disabled" : ''}
              onClick={handleClick}
              disabled={buttonDisabled}
              name="Good Plus (G+)"
              value={cdObj.condition.goodPlus || 0}>
              Add
            </button>
          </td>
          <td className="table-column">
            <button
							className={ buttonDisabled ? "disabled" : ''}
              onClick={handleClick}
              disabled={buttonDisabled}
              name="Very Good (VG)"
              value={cdObj.condition.veryGood || 0}>
              Add
            </button>
          </td>
          <td className="table-column">
            <button
							className={ buttonDisabled ? "disabled" : ''}
              onClick={handleClick}
              disabled={buttonDisabled}
              name="Very Good Plus (VG+)"
              value={cdObj.condition.veryGoodPlus || 0}>
              Add
            </button>
          </td>
          <td className="table-column">
            <button
							className={ buttonDisabled ? "disabled" : ''}
              onClick={handleClick}
              disabled={buttonDisabled}
              name="Near Mint (NM or M-)"
              value={cdObj.condition.nearMint || 0}>
              Add
            </button>
          </td>
          <td className="table-column">
            <button
							// className={buttonDisabled ? "table-column-button disabled" : "table-column-button"}
							className={ buttonDisabled ? "disabled" : ''}
              onClick={handleClick}
              disabled={buttonDisabled}
              name="Mint (M)"
              value={cdObj.condition.mint || 0}>
              Add
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
