import React from "react";

const Collection = () => {
  // call the database and display all the records

  return (
    <>
      <div className="App">Collection</div>
    </>
  );
};

export default Collection;
