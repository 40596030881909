import { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const {
  REACT_APP_DISCOGS_TOKEN: DISCOGS_TOKEN,
  REACT_APP_SERVER_PORT: SERVER_PORT,
  REACT_APP_SERVER_HOST: SERVER_HOST,
} = process.env;

const AlbumSelector = () => {
  const [albums, setAlbums] = useState([]);
  const { searchString } = useParams();

  const discogsApiHost = "https://api.discogs.com/";
  // const discogsDatabaseTitleURL = `${discogsApiHost}database/search?query=${searchString}&token=${DISCOGS_TOKEN}`;

  const headers = {
    Authorization: `Discogs token=${DISCOGS_TOKEN}`,
  };

  const options = {
    method: "get",
    headers,
  };

	const fetchAlbums = useCallback(async () => {
		const discogsDatabaseTitleURL = `${discogsApiHost}database/search?query=${searchString}&token=${DISCOGS_TOKEN}`;
	
		const fetchPage = async (url) => {

			const response = await axios.get(url, options);

			if (response.status === 200) {
				const { results, pagination } = response.data;
				setAlbums((prevAlbums) => [...prevAlbums, ...results]);
	
				const { urls } = pagination;
				// console.log('pagination:', pagination);
				if (urls.next && pagination.page < 10) {
					// Delay the next call by 1 second
					setTimeout(async () => {
						await fetchPage(urls.next);
					}, 1000);
				}
			}
		};
	
		fetchPage(discogsDatabaseTitleURL)
			.catch((error) => {
				console.log("Error : " + error);
			});
	}, [options]);
		
  useEffect(() => {
    fetchAlbums();
  }, []);

	const DisplayAlbums = () => {
		const [images, setImages] = useState({});
	
		const loadImage = (album) => {
			return new Promise((resolve, reject) => {
				const img = new Image();
				// img.src = album.thumb;
				img.onload = () => resolve(img);
				img.onerror = reject;
			});
		};
	
		const handleImageLoad = async (album) => {
			try {
				const img = await loadImage(album);
				setImages((prevImages) => ({ ...prevImages, [album.id]: img }));
			} catch (error) {
				console.error(`Failed to load image for album ${album.id}`, error);
			}
		};
	
		useEffect(() => {
			if (albums.length > 0) {
				albums.forEach((album) => {
					handleImageLoad(album);
				});
			}
		}, [albums]);
	
		if (albums.length > 0) {
			return albums.map((album) => {
				const getBarcode = (album) => {
					if (album?.barcode?.length > 0) {
						return album.barcode[0];
					}
				};
				const uuid = crypto.randomUUID();
				return (
					<div key={uuid}>
						{/* <Link to={`/${getBarcode(album)}`}>
							<img src={images[album.id]} alt={album.title}></img>
						</Link> */}
						<div>
						<Link to={`/${getBarcode(album)}`}>
							{album.title}
						</Link>
						</div>
						<div>{album.format?.map((form) => ` ${form}`)}</div>
					</div>
				);
			});
		}
	};

	
  // const DisplayAlbums = () => {
  //   if (albums.length > 0) {
  //     return albums.map((album) => {
  //       const getBarcode = (album) => {
  //         if (album?.barcode?.length > 0) {
  //           return album.barcode[0];
  //         }
  //       };
	// 			const uuid = crypto.randomUUID();
  //       return (
  //         <div key={uuid}>
  //           <Link to={`/${getBarcode(album)}`}>
  //             <img src={album.thumb} alt={album.title}></img>
  //           </Link>
	// 					<div>
	// 						{album.title}
	// 					</div>
  //           <div>{album.format?.map((form) => ` ${form}`)}</div>
  //         </div>
  //       );
  //     });
  //   }
  // };

  return (
    <div className="App">
      <div>AlbumSelector</div>
      <div>{searchString}</div>
      <div>
        <DisplayAlbums />
      </div>
    </div>
  );
};

export default AlbumSelector;
