import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import albumObjects from "../helpers/albumObjects";

const {
  REACT_APP_SERVER_PORT: SERVER_PORT,
  REACT_APP_SERVER_HOST: SERVER_HOST,
} = process.env;

let { collectionObj } = albumObjects

const CollectionSummary = () => {

	const [collectionData, setCollectionData] = useState(collectionObj);

	const { totalAlbums, totalValuation, totalCDs, totalDVDs } = collectionData

	function financial(num) {
		return Number.parseFloat(num).toFixed(2);
	}
	
	const fetchCollectionData = useCallback(async () => {
    try {
      await axios
        .get(`${SERVER_HOST}${SERVER_PORT}`)
        .then((response) => {
          if (response.status === 200) {
            const { data } = response;

						const totalValuation = data.reduce((acc, album) => {
							acc += parseFloat(album.value)
							return acc
						}, 0)
						const totalAlbums = data.length
						
						let totalCDs = 0
						let totalDVDs = 0
						for (let i = 0; i < data.length; i++) {
								if (data[i].format === 'CD') {
									totalCDs++
								}
								if (data[i].format === 'DVD') {
									totalDVDs++
								}
							}

							setCollectionData({
								totalAlbums,
								totalValuation: financial(totalValuation),
								totalCDs,
								totalDVDs,
							})
          }
        })
        .catch((error) => {
          console.log("Error : " + error);
        });
    } catch (error) {
      console.log("Error : " + error);
    }
  }, []);


	useEffect(() => {
		fetchCollectionData();
  }, [fetchCollectionData]);

  return (
    <>
      <div className="App">Collection Summary</div>
      <thead>
        <tr className="table-row">
          <th className="table-column">Total Albums</th>
          <th className="table-column">Total Valuation</th>
          <th className="table-column">Total CDs</th>
          <th className="table-column">DVDs</th>
        </tr>
      </thead>
      <tbody>
        <tr className="table-row">
          <td className="table-column">{totalAlbums}</td>
          <td className="table-column">${totalValuation}</td>
          <td className="table-column">{totalCDs}</td>
          <td className="table-column">{totalDVDs}</td>
        </tr>
      </tbody>
    </>
  );
};

export default CollectionSummary;
