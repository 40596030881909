export const VideoLinks = ({videos}) => {
	return (
    <>
		{videos.map((video) => {
			return (
      <div key={video.uri}>
        <a href={video.uri}>{video.title}</a>
      </div>
			)
		})}
    </>
  );
}
