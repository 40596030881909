export const TitleArtistInput = ({
  titleArtistInput = '',
  handleTitleChange,
  handleClick,
}) => {
  return (
    <div>
      <label htmlFor="titleArtist">Text:</label>
      <input
        type="text"
        id="titleArtist"
        name="titleArtist"
        value={titleArtistInput}
        onChange={handleTitleChange}
      />
      <button value={titleArtistInput} onClick={handleClick}>
        Search
      </button>
    </div>
  );
};
