let cdObj = {
	barcode: "",
	format: "",
	uri: "",
	title: "",
	cover_image: "",
	year: "",
	genre: [],
	style: [],
	relaseId: 0,
};

let releaseObj = {
	artists: [],
	artists_sort: "",
	extraartists: [], // ????
	title: "",
	notes: "",
	tracklist: [],
	videos: [],
};

let marketplaceObj = {
	"Fair (F)": { value: 0 },
	"Good (G)": { value: 0 },
	"Good Plus (G+)": { value: 0 },
	"Mint (M)": { value: 0 },
	"Near Mint (NM or M-)": { value: 0 },
	"Poor (P)": { value: 0 },
	"Very Good (VG)": { value: 0 },
	"Very Good Plus (VG+)": { value: 0 },
};

let collectionObj = {
	totalAlbums: 0,
	totalValuation: 0,
	totalCDs: 0,
	totalDVDs: 0,
}

module.exports = {
	cdObj,
	releaseObj,
	marketplaceObj,
	collectionObj
}