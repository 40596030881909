import { Link } from 'react-router-dom'

export const Header = () => {

  return (
    <div className="App">
      <div>
        <Link to={"/"} >Main</Link>
				{' ◦ '}
        <Link to={"collection"}>Collection</Link>
				{' ◦ '}
        <Link to={"summary"}>Summary</Link>
      </div>
    </div>
  );
};
