import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { ConditionTable } from "./ConditionTable";
import { BarcodeInput } from "./BarcodeInput";
import { VideoLinks } from "./VideoLinks";
import { Tracklist } from "./Tracklist";
import { TitleArtistInput } from "./TitleArtistInput";
import albumObjects from "../helpers/albumObjects";

const {
  REACT_APP_DISCOGS_TOKEN: DISCOGS_TOKEN,
  REACT_APP_SERVER_PORT: SERVER_PORT,
  REACT_APP_SERVER_HOST: SERVER_HOST,
} = process.env;

const Main = () => {
	const navigate = useNavigate();
	
	let { cdObj, releaseObj, marketplaceObj } = albumObjects

  const { paramsBarcode } = useParams();
  const [albumData, setAlbumData] = useState(cdObj);
  const [releaseData, setReleaseData] = useState(releaseObj);
  const [marketplaceData, setMarketplaceData] = useState(marketplaceObj);
  const [message, setMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [barcodeInput, setBarcodeInput] = useState("");
  const [titleArtistInput, setTitleArtistInput] = useState("");
  const [callApi, setCallApi] = useState(true);

  const discogsApiHost = "https://api.discogs.com/";
  const discogsDatabaseBarcodeURL = `${discogsApiHost}database/search?barcode=${barcodeInput}&token=${DISCOGS_TOKEN}`;
  const discogsDatabaseTitleURL = `${discogsApiHost}database/search?query=${titleArtistInput}&token=${DISCOGS_TOKEN}`;
  const discogsMarketplaceURL = `${discogsApiHost}marketplace/price_suggestions/${albumData.id}`;
  const discogsReleaseURL = `${discogsApiHost}releases/${albumData.id}`;
  const discogsMain = "https://www.discogs.com";

  const headers = {
    Authorization: `Discogs token=${DISCOGS_TOKEN}`,
  };

  const options = {
    method: "get",
    headers,
  };

  const fetchAlbumData = useCallback(async () => {
    if (barcodeInput && !titleArtistInput && callApi) {
      try {
        await axios
          .get(discogsDatabaseBarcodeURL, options)
          .then((response) => {
            if (response.status === 200 && response.data.results.length === 0) {
							// when discogs can't find an album, sometimes the barcode is too long, so we slice off the last digit and try again
							setTimeout(() => {
								setBarcodeInput(barcodeInput.slice(0, -1))
								setMessage('Trying again. . .')
							}, 100);
						} else if (response.status === 200) {
							const { results } = response.data;
							console.log('results:', results);
              results.forEach((result) => {
								if (result.country === 'US' && result.id !== result.master_id) {
									setAlbumData(result);
								} else if (result.id !== result.master_id){
									setAlbumData(result);
								}
                setCallApi(false);
                setButtonDisabled(false);
                setMessage("");
                setBarcodeInput("");
              });
            }
          })
          .catch((error) => {
            setCallApi(true);
            console.log("Error : " + error);
          });
      } catch (error) {
        setCallApi(true);
        console.log(error);
      }
    } else if (!barcodeInput && titleArtistInput && callApi) {
      try {
        await axios
          .get(discogsDatabaseTitleURL, options)
          .then((response) => {
						if (response.status === 200) {
              const { results } = response.data;
              results.forEach((result) => {
                // if (result.country === "US" && result.type === "release" && result.format.includes("CD") && !result.format.includes("Single") && !result.format.includes("Vinyl") ) {
                // console.log('result:', result);
                setAlbumData(result);
                setCallApi(false);
                setButtonDisabled(false);
                setMessage("");
              });
            }
          })
          .catch((error) => {
            console.log("Error : " + error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [discogsDatabaseBarcodeURL, options, barcodeInput, titleArtistInput]);

  const fetchMarketplaceData = useCallback(async () => {
    try {
      await axios
        .get(discogsMarketplaceURL, options)
        .then((response) => {
          if (response.status === 200) {
            const { data } = response;
            setMarketplaceData(data);
          }
        })
        .catch((error) => {
          console.log("Error : " + error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [discogsMarketplaceURL, albumData.id]);

  const fetchReleaseData = useCallback(async () => {
    try {
      await axios
        .get(discogsReleaseURL)
        .then((response) => {
          if (response.status === 200) {
            const { data } = response;
            setReleaseData(data);
          }
        })
        .catch((error) => {
          console.log(error);
          setCallApi(true);
          console.log("Error : " + error);
        });
    } catch (error) {
      console.log("Error : " + error);
    }
  }, [discogsReleaseURL]);

  useEffect(() => {
    setBarcodeInput(paramsBarcode);
    setTimeout(() => {
      fetchAlbumData();
    }, 50); // discogs api limits 1 call a second before getting rate limited
  }, [fetchAlbumData, barcodeInput, paramsBarcode]);

  useEffect(() => {
    if (albumData.id) {
      fetchMarketplaceData();
    }
  }, [fetchMarketplaceData, albumData.id]);

  useEffect(() => {
    if (albumData.id) {
      fetchReleaseData();
    }
  }, [fetchReleaseData, albumData.id]);

  let timeoutId = null;

  const handleBarcodeChange = (e) => {
    const value = e.target.value;
		// barcodeArray.push(value)
		// let newArray = barcodeArray.map(char => char.replace(/\s/g, ""));
		// newArray = newArray.toString().replace(/,/g, "")
		// console.log('newArray:', newArray);
		
    // Clear the previous timeout (if any)
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    // Set a new timeout to log "Final Barcode" after 51ms
    timeoutId = setTimeout(() => {
			setBarcodeInput(value);
      setCallApi(true);
    }, 500);
  };

  const handleAddButtonClick = async (e) => {
    cdObj.condition = e.target.name;
    cdObj.value = parseFloat(e.target.value);

    const response = await axios.post(`${SERVER_HOST}${SERVER_PORT}/`, {cdObj});
    setMessage(response.data.message);
    setButtonDisabled(response.data.disableButton);
  };

  const handleClick = (e) => {
    const albumSearchString = e.target.value;
    navigate(`/select/${albumSearchString}`);
  };

  const handleTitleChange = (e) => {
    setTitleArtistInput(e.target.value);
    setCallApi(false);
  };

  // const handleSearchButtonClick = async (value) => {
  // 	value.preventDefault()
  // 	console.log('handleSearchButtonClick/value:', value);
  // 	// const response = await axios.post(`${SERVER_HOST}${SERVER_PORT}/`, { value });
  // 	// console.log('response:', response);
  // 	// setCallApi(true)

  // 	// fetchMarketplaceData()
  // 	// const searchString = e.target.value
  // }

  cdObj = {
    barcode: albumData.barcode[0] || '',
    format: albumData.format[0] || "Missing format",
    uri: `${discogsMain}${albumData.uri}` || "Missing URI",
    title: releaseData?.title || "Missing title",
    albumArtists: releaseData?.artists_sort || "Missing album artist",
    cover_image: albumData.cover_image || "Missing cover image",
    year: albumData.year || "No year",
    genre: albumData.genre.join(", ") || [],
    style: albumData.style.join(", ") || [],
    relaseId: albumData.id || "Missing release Id",
    condition: {
      poor: marketplaceData["Poor (P)"]?.value.toFixed(2),
      fair: marketplaceData["Fair (F)"]?.value.toFixed(2),
      good: marketplaceData["Good (G)"]?.value.toFixed(2),
      goodPlus: marketplaceData["Good Plus (G+)"]?.value.toFixed(2),
      veryGood: marketplaceData["Very Good (VG)"]?.value.toFixed(2),
      veryGoodPlus: marketplaceData["Very Good Plus (VG+)"]?.value.toFixed(2),
      nearMint: marketplaceData["Near Mint (NM or M-)"]?.value.toFixed(2),
      mint: marketplaceData["Mint (M)"]?.value.toFixed(2),
    },
    artists: releaseData?.artists || [],
    notes: releaseData?.notes || [],
    tracklist: releaseData?.tracklist || [],
    videos: releaseData?.videos || [],
  };

  return (
    <div className="App">
      {cdObj?.barcode ? (
        <>
          <BarcodeInput
            barcodeInput={barcodeInput}
            handleBarcodeChange={handleBarcodeChange}
            setBarcodeInput={setBarcodeInput}
          />
          <TitleArtistInput
            titleArtistInput={titleArtistInput}
            handleTitleChange={handleTitleChange}
            handleClick={handleClick}
          />
          {message ? <> {message} </> : ""}
          {/* <a href="/collection" >Collection</a> */}
          {/* const response = await axios.get(`${SERVER_HOST}${PORT}/`); //  this works to get the collection from the database */}
          <p>Barcode: {cdObj.barcode}</p>
          <p>
            <a href={cdObj.uri}>
              {cdObj.title} - {cdObj.albumArtists}
            </a>
          </p>
          <p>Album Title: {cdObj.title}</p>
          <p>Album Artist(s): {cdObj.albumArtists}</p>
          <div>Year: {cdObj.year}</div>
          <p>
            <img src={cdObj.cover_image} alt={cdObj.title} width="250px" />
          </p>
          <div>Style: {cdObj.style}</div>
          <div>Genre: {cdObj.genre}</div>
          <div>Format: {cdObj.format}</div>
          {/* <div>Release ID: {cdObj.relaseId}</div> */}
          <ConditionTable
            cdObj={cdObj}
            buttonDisabled={buttonDisabled}
            handleClick={handleAddButtonClick}
          />
          <Tracklist tracks={cdObj.tracklist} />
          <p>Notes: {cdObj.notes}</p>
          <VideoLinks videos={cdObj.videos} />
        </>
      ) : (
        <>
          {/* <p>Loading...</p> */}
          <BarcodeInput
            barcodeInput={barcodeInput}
            handleBarcodeChange={handleBarcodeChange}
            setBarcodeInput={setBarcodeInput}
          />
          <TitleArtistInput
            titleArtistInput={titleArtistInput}
            handleTitleChange={handleTitleChange}
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
};

export default Main;
