export const Tracklist = ({tracks}) => {
  return (
    <>
      {tracks.map((track) => {
        return (
          <div key={track.position}>
            {track.position} {track.title} - {track.duration}
          </div>
        );
      })}
    </>
  );
};
